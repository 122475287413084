<template>
  <div id="RangchaProductDetail">
    <div class="banner">
      <img
        v-if="product_id == 1 || product_id == 2 || product_id == 11"
        src="https://rangcha-oss.oss-cn-beijing.aliyuncs.com/v3_website/product/banner_rangcha_5.jpg"
        alt=""
        srcset=""
      />
     
      <img
        v-else-if="product_id == 5 "
        src="https://rangcha-oss.oss-cn-beijing.aliyuncs.com/v3_website/product/banner_rangcha_2.jpg"
        alt=""
        srcset=""
      />
      <img
        v-else-if="product_id == 7 || product_id == 8"
        src="https://rangcha-oss.oss-cn-beijing.aliyuncs.com/v3_website/product/banner_rangcha_4.jpeg"
        alt=""
        srcset=""
      />

      <img
        v-else-if="product_id == 9 || product_id == 16|| product_id == 18|| product_id == 15|| product_id == 19|| product_id == 13|| product_id == 12|| product_id == 14"
        src="https://rangcha-oss.oss-cn-beijing.aliyuncs.com/v3_website/product/product-background.jpg"
        alt=""
        srcset=""
      />
      
      <img
        v-else-if="product_id == 20|| product_id == 21"
        src="https://rangcha-oss.oss-cn-beijing.aliyuncs.com/v3_website/product/product-background.jpg"
        alt=""
        srcset=""
      />
      <img
        v-else-if="product_id == 22|| product_id == 23"
        src="https://rangcha-oss.oss-cn-beijing.aliyuncs.com/v3_website/product/product-background2.jpg"
        alt=""
        srcset=""
      />
    </div>
    <!-- 让茶无糖果味茶系列 - 白桃青柠红茶 -->
    <div class="rangcha" v-if="product_id == 4">
      <div class="detail_content">
        <div class="p_img">
          <img
            src="https://rangcha-oss.oss-cn-beijing.aliyuncs.com/v3_website/202401/baitaoqingning_details.png"
            alt=""
            srcset=""
          />
        </div>
        <div class="p_dec_box">
          <div class="series_title">
            <span>让茶.无糖果茶系列</span>
            <img src="../../assets/productDetail/b_a.png" alt="" srcset="" />
          </div>
          <div class="title">白桃青柠红茶</div>
          <div class="dec_box">
            <div class="dec_item_left">
              <div class="dec_title">产品介绍</div>
              <div class="dec_content">
                <div class="dec_content_item">青柠加白桃酸甜可口</div>
                <div class="dec_content_item">搭配醇香的锡兰红茶</div>
                <div class="dec_content_item">一口充盈酸甜与茶香</div>
              </div>
            </div>
            <div class="dec_item_right">
              <div class="dec_title">产品特性</div>
              <div class="dec_item">
                <div class="dec_icon">
                  <img
                    src="../../assets/productDetail/icon.png"
                    alt=""
                    srcset=""
                  />
                </div>
                <div class="dec_item_content">0糖0能量，减能量不减美味</div>
              </div>
              <div class="dec_item">
                <div class="dec_icon">
                  <img
                    src="../../assets/productDetail/icon.png"
                    alt=""
                    srcset=""
                  />
                </div>
                <div class="dec_item_content">100%真茶萃取，不添加茶粉或茶浓缩液</div>
              </div>
              <div class="dec_item">
                <div class="dec_icon">
                  <img
                    src="../../assets/productDetail/icon.png"
                    alt=""
                    srcset=""
                  />
                </div>
                <div class="dec_item_content">添加水果浓缩汁，酸甜果味，清爽好喝</div>
              </div>
            </div>
            <div class="dec_item_mid">
              <div class="dec_title">产品规格</div>
              <div class="dec_content">净含量：450mL</div>
            </div>
          </div>
        </div>
      </div>
    </div>
    <!-- 让茶无糖果味茶系列 - 葡萄乌龙茶 -->
    <div class="rangcha" v-if="product_id == 9">
      <div class="detail_content">
        <div class="p_img">
          <img
            src="https://rangcha-oss.oss-cn-beijing.aliyuncs.com/v3_website/product/3-1.png"
            alt=""
            srcset=""
          />
        </div>
        <div class="p_dec_box">
          <div class="series_title">
            <span>让茶.无糖果茶系列</span>
            <img src="../../assets/productDetail/b_a.png" alt="" srcset="" />
          </div>
          <div class="title">葡萄乌龙茶</div>
          <div class="dec_box">
            <div class="dec_item_left">
              <div class="dec_title">产品介绍</div>
              <div class="dec_content">
                <div class="dec_content_item">葡萄汁原料选自西班牙</div>
                <div class="dec_content_item">搭配醇香闽南乌龙茶</div>
                <div class="dec_content_item">宛若咬破整颗葡萄般，酸甜怡人</div>
              </div>
            </div>
            <div class="dec_item_right">
              <div class="dec_title">产品特性</div>
              <div class="dec_item">
                <div class="dec_icon">
                  <img
                    src="../../assets/productDetail/icon.png"
                    alt=""
                    srcset=""
                  />
                </div>
                <div class="dec_item_content">0糖0能量，好喝不怕胖</div>
              </div>
              <div class="dec_item">
                <div class="dec_icon">
                  <img
                    src="../../assets/productDetail/icon.png"
                    alt=""
                    srcset=""
                  />
                </div>
                <div class="dec_item_content">100%真茶萃取，添加浓缩葡萄汁</div>
              </div>
              <div class="dec_item">
                <div class="dec_icon">
                  <img
                    src="../../assets/productDetail/icon.png"
                    alt=""
                    srcset=""
                  />
                </div>
                <div class="dec_item_content">每瓶含100mg维生素C，满足成人每日所需维生素C</div>
              </div>
            </div>
            <div class="dec_item_mid">
              <div class="dec_title">产品规格</div>
              <div class="dec_content">净含量：500mL</div>
            </div>
          </div>
        </div>
      </div>
    </div>
    <!-- 让茶无糖果味茶系列 - 青梅龙井 -->
    <div class="rangcha" v-if="product_id == 15">
      <div class="detail_content">
        <div class="p_img">
          <img
            src="https://rangcha-oss.oss-cn-beijing.aliyuncs.com/v3_website/product/3-4.png"
            alt=""
            srcset=""
          />
        </div>
        <div class="p_dec_box">
          <div class="series_title">
            <span>让茶.无糖果茶系列</span>
            <img src="../../assets/productDetail/b_a.png" alt="" srcset="" />
          </div>
          <div class="title">青梅龙井茶</div>
          <div class="dec_box">
            <div class="dec_item_left">
              <div class="dec_title">产品介绍</div>
              <div class="dec_content">
                <div class="dec_content_item">青梅汁原料精选自黄山</div>
                <div class="dec_content_item">搭配800米以上高山龙井茶</div>
                <div class="dec_content_item">酸爽青梅，酸甜爽口</div>
              </div>
            </div>
            <div class="dec_item_right">
              <div class="dec_title">产品特性</div>
              <div class="dec_item">
                <div class="dec_icon">
                  <img
                    src="../../assets/productDetail/icon.png"
                    alt=""
                    srcset=""
                  />
                </div>
                <div class="dec_item_content">每瓶含100mg维生素C，满足成人每日所需维生素C</div>
              </div>
              <div class="dec_item">
                <div class="dec_icon">
                  <img
                    src="../../assets/productDetail/icon.png"
                    alt=""
                    srcset=""
                  />
                </div>
                <div class="dec_item_content">0糖0能量，好喝不怕胖</div>
              </div>
              <div class="dec_item">
                <div class="dec_icon">
                  <img
                    src="../../assets/productDetail/icon.png"
                    alt=""
                    srcset=""
                  />
                </div>
                <div class="dec_item_content">100%真茶萃取</div>
              </div>
              <div class="dec_item">
                <div class="dec_icon">
                  <img
                    src="../../assets/productDetail/icon.png"
                    alt=""
                    srcset=""
                  />
                </div>
                <div class="dec_item_content">添加浓缩青梅汁</div>
              </div>
            </div>
            <div class="dec_item_mid">
              <div class="dec_title">产品规格</div>
              <div class="dec_content">净含量：500mL</div>
            </div>
          </div>
        </div>
      </div>
    </div>


    <!-- 让茶无糖果味茶系列 - 柠C茉莉 -->
    <div class="rangcha" v-if="product_id == 16">
      <div class="detail_content">
        <div class="p_img">
          <img
            src="https://rangcha-oss.oss-cn-beijing.aliyuncs.com/v3_website/product/3-2.png"
            alt=""
            srcset=""
          />
        </div>
        <div class="p_dec_box">
          <div class="series_title">
            <span>让茶.无糖果茶系列</span>
            <img src="../../assets/productDetail/b_a.png" alt="" srcset="" />
          </div>
          <div class="title">柠C茉莉茶</div>
          <div class="dec_box">
            <div class="dec_item_left">
              <div class="dec_title">产品介绍</div>
              <div class="dec_content">
                <div class="dec_content_item">柠檬汁原料精选自四川安岳</div>
                <div class="dec_content_item">搭配高山茉莉花茶，花香怡人</div>
                <div class="dec_content_item">酸爽柠檬 花香茉莉</div>
              </div>
            </div>
            <div class="dec_item_right">
              <div class="dec_title">产品特性</div>
              <div class="dec_item">
                <div class="dec_icon">
                  <img
                    src="../../assets/productDetail/icon.png"
                    alt=""
                    srcset=""
                  />
                </div>
                <div class="dec_item_content">每瓶含100mg维生素C，满足成人每日所需维生素C</div>
              </div>
              <div class="dec_item">
                <div class="dec_icon">
                  <img
                    src="../../assets/productDetail/icon.png"
                    alt=""
                    srcset=""
                  />
                </div>
                <div class="dec_item_content">0糖0能量，好喝不怕胖</div>
              </div>
              <div class="dec_item">
                <div class="dec_icon">
                  <img
                    src="../../assets/productDetail/icon.png"
                    alt=""
                    srcset=""
                  />
                </div>
                <div class="dec_item_content">100%真茶萃取</div>
              </div>
               <div class="dec_item">
                <div class="dec_icon">
                  <img
                    src="../../assets/productDetail/icon.png"
                    alt=""
                    srcset=""
                  />
                </div>
                <div class="dec_item_content">添加浓缩柠檬汁</div>
              </div>
            </div>
            <div class="dec_item_mid">
              <div class="dec_title">产品规格</div>
              <div class="dec_content">净含量：500mL</div>
            </div>
          </div>
        </div>
      </div>
    </div>
    <!-- 让茶无糖果味茶系列 - 橙C乌龙茶 -->
    <div class="rangcha" v-if="product_id == 19">
      <div class="detail_content">
        <div class="p_img">
          <img
            src="https://rangcha-oss.oss-cn-beijing.aliyuncs.com/v3_website/product/3-5.png"
            alt=""
            srcset=""
          />
        </div>
        <div class="p_dec_box">
          <div class="series_title">
            <span>让茶.无糖果茶系列</span>
            <img src="../../assets/productDetail/b_a.png" alt="" srcset="" />
          </div>
          <div class="title">橙C乌龙茶</div>
          <div class="dec_box">
            <div class="dec_item_left">
              <div class="dec_title">产品介绍</div>
              <div class="dec_content">
                <div class="dec_content_item">橙汁原料精选自江西</div>
                <div class="dec_content_item">搭配高山乌龙茶，茶香醇爽</div>
                <div class="dec_content_item">酸甜橙C，清香乌龙</div>
              </div>
            </div>
            <div class="dec_item_right">
              <div class="dec_title">产品特性</div>
              <div class="dec_item">
                <div class="dec_icon">
                  <img
                    src="../../assets/productDetail/icon.png"
                    alt=""
                    srcset=""
                  />
                </div>
                <div class="dec_item_content">每瓶含100mg维生素C，满足成人每日所需维生素C</div>
              </div>
              <div class="dec_item">
                <div class="dec_icon">
                  <img
                    src="../../assets/productDetail/icon.png"
                    alt=""
                    srcset=""
                  />
                </div>
                <div class="dec_item_content">0糖0能量，好喝不怕胖</div>
              </div>
              <div class="dec_item">
                <div class="dec_icon">
                  <img
                    src="../../assets/productDetail/icon.png"
                    alt=""
                    srcset=""
                  />
                </div>
                <div class="dec_item_content">100%真茶萃取</div>
              </div>
              <div class="dec_item">
                <div class="dec_icon">
                  <img
                    src="../../assets/productDetail/icon.png"
                    alt=""
                    srcset=""
                  />
                </div>
                <div class="dec_item_content">添加浓缩橙汁</div>
              </div>
            </div>
            <div class="dec_item_mid">
              <div class="dec_title">产品规格</div>
              <div class="dec_content">净含量：500mL</div>
            </div>
          </div>
        </div>
      </div>
    </div>
    
<!-- 让茶无糖果味茶系列 - 柚C茉莉茶 -->
<div class="rangcha" v-if="product_id == 18">
      <div class="detail_content">
        <div class="p_img">
          <img
            src="https://rangcha-oss.oss-cn-beijing.aliyuncs.com/v3_website/product/3-3.png"
            alt=""
            srcset=""
          />
        </div>
        <div class="p_dec_box">
          <div class="series_title">
            <span>让茶.无糖果茶系列</span>
            <img src="../../assets/productDetail/b_a.png" alt="" srcset="" />
          </div>
          <div class="title">柚C茉莉茶</div>
          <div class="dec_box">
            <div class="dec_item_left">
              <div class="dec_title">产品介绍</div>
              <div class="dec_content">
                <div class="dec_content_item">西柚汁原料精选自福建</div>
                <div class="dec_content_item">搭配高山茉莉花茶，花香怡人</div>
                <div class="dec_content_item">酸甜柚C，花香茉莉</div>
              </div>
            </div>
            <div class="dec_item_right">
              <div class="dec_title">产品特性</div>
              <div class="dec_item">
                <div class="dec_icon">
                  <img
                    src="../../assets/productDetail/icon.png"
                    alt=""
                    srcset=""
                  />
                </div>
                <div class="dec_item_content">每瓶含100mg维生素C，满足成人每日所需维生素C</div>
              </div>
              <div class="dec_item">
                <div class="dec_icon">
                  <img
                    src="../../assets/productDetail/icon.png"
                    alt=""
                    srcset=""
                  />
                </div>
                <div class="dec_item_content">0糖0能量，好喝不怕胖</div>
              </div>
              <div class="dec_item">
                <div class="dec_icon">
                  <img
                    src="../../assets/productDetail/icon.png"
                    alt=""
                    srcset=""
                  />
                </div>
                <div class="dec_item_content">100%真茶萃取</div>
              </div>
              <div class="dec_item">
                <div class="dec_icon">
                  <img
                    src="../../assets/productDetail/icon.png"
                    alt=""
                    srcset=""
                  />
                </div>
                <div class="dec_item_content">添加浓缩西柚汁</div>
              </div>
            </div>
            <div class="dec_item_mid">
              <div class="dec_title">产品规格</div>
              <div class="dec_content">净含量：500mL</div>
            </div>
          </div>
        </div>
      </div>
    </div>
    <!-- 让茶高山系列 - 高山绿茶 -->
    <div class="rangcha" v-if="product_id == 5">
      <div class="detail_content">
        <div class="p_img">
          <img
            src="https://rangcha-oss.oss-cn-beijing.aliyuncs.com/v3_website/product/1-6.png"
            alt=""
            srcset=""
          />
        </div>
        <div class="p_dec_box">
          <div class="series_title">
            <span>让茶.高山纯茶系列</span>
            <img src="../../assets/productDetail/b_a.png" alt="" srcset="" />
          </div>
          <div class="title">高山绿茶</div>
          <div class="dec_box">
            <div class="dec_item_left">
              <div class="dec_title">产品介绍</div>
              <div class="dec_content">
                <div class="dec_content_item">来自海拔1000米四川雅安高山茶园</div>
                <div class="dec_content_item">精选茶树品种中茶108</div>
                <div class="dec_content_item">创新融合蒸青、烘青2种杀青工艺</div>
              </div>
            </div>
            <div class="dec_item_right">
              <div class="dec_title">产品特性</div>
              <div class="dec_item">
                <div class="dec_icon">
                  <img
                    src="../../assets/productDetail/icon.png"
                    alt=""
                    srcset=""
                  />
                </div>
                <div class="dec_item_content">添加茉莉花茶，入口清香甘爽</div>
              </div>
              <div class="dec_item">
                <div class="dec_icon">
                  <img
                    src="../../assets/productDetail/icon.png"
                    alt=""
                    srcset=""
                  />
                </div>
                <div class="dec_item_content">100%原叶茶萃取</div>
              </div>
              <div class="dec_item">
                <div class="dec_icon">
                  <img
                    src="../../assets/productDetail/icon.png"
                    alt=""
                    srcset=""
                  />
                </div>
                <div class="dec_item_content">
                  0糖0脂0能量 不添加防腐剂不添加香精
                </div>
              </div>
            </div>
            <div class="dec_item_mid">
              <div class="dec_title">产品规格</div>
              <div class="dec_content">净含量：500mL</div>
            </div>
          </div>
        </div>
      </div>
    </div>
    <!-- 让茶高山系列 - 高山乌龙茶 -->
    <div class="rangcha" v-if="product_id == 6">
      <div class="detail_content">
        <div class="p_img">
          <img
            src="https://rangcha-oss.oss-cn-beijing.aliyuncs.com/v3_website/product/1-7.png"
            alt=""
            srcset=""
          />
        </div>
        <div class="p_dec_box">
          <div class="series_title">
            <span>让茶.高山纯茶系列</span>
            <img src="../../assets/productDetail/b_a.png" alt="" srcset="" />
          </div>
          <div class="title">高山乌龙茶</div>
          <div class="dec_box">
            <div class="dec_item_left">
              <div class="dec_title">产品介绍</div>
              <div class="dec_content">
                <div class="dec_content_item">
                  来自海拔1600米以上的云南腾冲高山茶园
                </div>
                <div class="dec_content_item">精选茶树品种青心乌龙</div>
                <div class="dec_content_item">香气如兰似桂、花香带果香</div>
              </div>
            </div>
            <div class="dec_item_right">
              <div class="dec_title">产品特性</div>
              <div class="dec_item">
                <div class="dec_icon">
                  <img
                    src="../../assets/productDetail/icon.png"
                    alt=""
                    srcset=""
                  />
                </div>
                <div class="dec_item_content">滋味饱满，畅饮不苦涩</div>
              </div>
              <div class="dec_item">
                <div class="dec_icon">
                  <img
                    src="../../assets/productDetail/icon.png"
                    alt=""
                    srcset=""
                  />
                </div>
                <div class="dec_item_content">100%原叶茶萃取</div>
              </div>
              <div class="dec_item">
                <div class="dec_icon">
                  <img
                    src="../../assets/productDetail/icon.png"
                    alt=""
                    srcset=""
                  />
                </div>
                <div class="dec_item_content">
                  0糖0脂0能量 不添加防腐剂不添加香精
                </div>
              </div>
            </div>
            <div class="dec_item_mid">
              <div class="dec_title">产品规格</div>
              <div class="dec_content">净含量：500mL</div>
            </div>
          </div>
        </div>
      </div>
    </div>
    <!-- 让茶草本系列 - 茉莉花茶 -->
    <div class="rangcha" v-if="product_id == 1">
      <div class="detail_content">
        <div class="p_img">
          <img
            src="https://rangcha-oss.oss-cn-beijing.aliyuncs.com/v3_website/product/caoben_p_d_molihua.png"
            alt=""
            srcset=""
          />
        </div>
        <div class="p_dec_box">
          <div class="series_title">
            <span>让茶.草本茶系列</span>
            <img src="../../assets/productDetail/b_a.png" alt="" srcset="" />
          </div>
          <div class="title">茉莉花茶</div>
          <div class="dec_box">
            <div class="dec_item_left">
              <div class="dec_title">产品介绍</div>
              <div class="dec_content">
                <div class="dec_content_item">
                  反复六次窨花，充分汲取花香精粹
                </div>
                <div class="dec_content_item">
                  精心把控花、茶配比3：2
                </div>
                <div class="dec_content_item">
                  花茶制作使用广西横州100%新鲜茉莉花
                </div>
                <div class="dec_content_item">
                  缘起东方草本，精选海拔600米以上高山茉莉花茶
                </div>
              </div>
            </div>
            <div class="dec_item_right">
              <div class="dec_title">产品特性</div>
              <div class="dec_item">
                <div class="dec_icon">
                  <img
                    src="../../assets/productDetail/icon.png"
                    alt=""
                    srcset=""
                  />
                </div>
                <div class="dec_item_content">
                  清爽甘甜少苦涩， 100%原叶茶萃取
                </div>
              </div>
              <div class="dec_item">
                <div class="dec_icon">
                  <img
                    src="../../assets/productDetail/icon.png"
                    alt=""
                    srcset=""
                  />
                </div>
                <div class="dec_item_content">
                  六次窨花制作
                </div>
              </div>
              <div class="dec_item">
                <div class="dec_icon">
                  <img
                    src="../../assets/productDetail/icon.png"
                    alt=""
                    srcset=""
                  />
                </div>
                <div class="dec_item_content">
                  0糖0脂0能量不添加防腐剂不添加香精
                </div>
              </div>
            </div>
            <div class="dec_item_mid">
              <div class="dec_title">产品规格</div>
              <div class="dec_content">净含量：500mL</div>
            </div>
          </div>
        </div>
      </div>
    </div>
    <!-- 让茶草本系列 - 普洱菊花茶 -->
    <div class="rangcha" v-if="product_id == 2">
      <div class="detail_content">
        <div class="p_img">
          <img
            src="https://rangcha-oss.oss-cn-beijing.aliyuncs.com/v3_website/product/caoben_p_d_puerjuhuai.png"
            alt=""
            srcset=""
          />
        </div>
        <div class="p_dec_box">
          <div class="series_title">
            <span>让茶.草本茶系列</span>
            <img src="../../assets/productDetail/b_a.png" alt="" srcset="" />
          </div>
          <div class="title">普洱菊花茶</div>
          <div class="dec_box">
            <div class="dec_item_left">
              <div class="dec_title">产品介绍</div>
              <div class="dec_content">
                <div class="dec_content_item">
                  缘起东方草本，辅以现代科学，
                </div>
                <div class="dec_content_item">
                  精选海拔1000米以上高山普洱茶，
                </div>
                <div class="dec_content_item">
                  搭配菊花、桂圆、枸杞子、薄荷、罗汉果，
                </div>
                <div class="dec_content_item">
                  一瓶6种植物食材，释放自然甘甜。
                </div>
              </div>
            </div>
            <div class="dec_item_right">
              <div class="dec_title">产品特性</div>
              <div class="dec_item">
                <div class="dec_icon">
                  <img
                    src="../../assets/productDetail/icon.png"
                    alt=""
                    srcset=""
                  />
                </div>
                <div class="dec_item_content">
                  清爽甘甜少苦涩，100%原叶茶萃取
                </div>
              </div>
              <div class="dec_item">
                <div class="dec_icon">
                  <img
                    src="../../assets/productDetail/icon.png"
                    alt=""
                    srcset=""
                  />
                </div>
                <div class="dec_item_content">
                  6种植物食材搭配，每瓶含有膳食纤维≧7.5g
                </div>
              </div>
              <div class="dec_item">
                <div class="dec_icon">
                  <img
                    src="../../assets/productDetail/icon.png"
                    alt=""
                    srcset=""
                  />
                </div>
                <div class="dec_item_content">
                  0糖0脂0能量不添加香精不添加防腐剂
                </div>
              </div>
            </div>
            <div class="dec_item_mid">
              <div class="dec_title">产品规格</div>
              <div class="dec_content">净含量：500mL</div>
            </div>
          </div>
        </div>
      </div>
    </div>

    <!-- 让茶草本系列 - 大麦清茶 -->
    <div class="rangcha" v-if="product_id == 11">
      <div class="detail_content">
        <div class="p_img">
          <img
            src="https://rangcha-oss.oss-cn-beijing.aliyuncs.com/v3_website/product/caoben_p_d_damaiqingcha.png"
            alt=""
            srcset=""
          />
        </div>
        <div class="p_dec_box">
          <div class="series_title">
            <span>让茶.草本茶系列</span>
            <img src="../../assets/productDetail/b_a.png" alt="" srcset="" />
          </div>
          <div class="title">大麦清茶</div>
          <div class="dec_box">
            <div class="dec_item_left">
              <div class="dec_title">产品介绍</div>
              <div class="dec_content">
                <div class="dec_content_item">
                  麦香四溢，香醇爽滑
                </div>
                <div class="dec_content_item">
                  2种大麦拼配，膳食纤维添加
                </div>
              </div>
            </div>
            <div class="dec_item_right">
              <div class="dec_title">产品特性</div>
              <div class="dec_item">
                <div class="dec_icon">
                  <img
                    src="../../assets/productDetail/icon.png"
                    alt=""
                    srcset=""
                  />
                </div>
                <div class="dec_item_content">
                  六棱大麦+二棱大麦大麦拼配，风味丰盈
                </div>
              </div>
              <div class="dec_item">
                <div class="dec_icon">
                  <img
                    src="../../assets/productDetail/icon.png"
                    alt=""
                    srcset=""
                  />
                </div>
                <div class="dec_item_content">
                  每瓶含有膳食纤维≥7.5g
                </div>
              </div>
              <div class="dec_item">
                <div class="dec_icon">
                  <img
                    src="../../assets/productDetail/icon.png"
                    alt=""
                    srcset=""
                  />
                </div>
                <div class="dec_item_content">
                  100%烘焙大麦萃取
                </div>
              </div>
              <div class="dec_item">
                <div class="dec_icon">
                  <img
                    src="../../assets/productDetail/icon.png"
                    alt=""
                    srcset=""
                  />
                </div>
                <div class="dec_item_content">
                  0糖0脂0咖啡因，不添加香精不添加防腐剂
                </div>
              </div>
            </div>
            <div class="dec_item_mid">
              <div class="dec_title">产品规格</div>
              <div class="dec_content">净含量：500mL</div>
            </div>
          </div>
        </div>
      </div>
    </div>
    <!-- 12 高山花茶系列 茉莉乌龙茶 -->
    <div class="rangcha" v-if="product_id == 12">
      <div class="detail_content">
        <div class="p_img">
          <img
            src="https://rangcha-oss.oss-cn-beijing.aliyuncs.com/v3_website/product/3-2-1.png"
            alt=""
            srcset=""
          />
        </div>
        <div class="p_dec_box">
          <div class="series_title">
            <span>让茶.高山茶系列</span>
            <img src="../../assets/productDetail/b_a.png" alt="" srcset="" />
          </div>
          <div class="title">茉莉乌龙茶</div>
          <div class="dec_box">
            <div class="dec_item_left">
              <div class="dec_title">产品介绍</div>
              <div class="dec_content">
                <div class="dec_content_item">茉莉清新，乌龙醇爽</div>
                <div class="dec_content_item">精选海拔800米以上茉莉乌龙茶</div>
                <div class="dec_content_item">茉莉鲜花窨制工艺</div>
              </div>
            </div>
            <div class="dec_item_right">
              <div class="dec_title">产品特性</div>
              <div class="dec_item">
                <div class="dec_icon">
                  <img
                    src="../../assets/productDetail/icon.png"
                    alt=""
                    srcset=""
                  />
                </div>
                <div class="dec_item_content">广西横州新鲜茉莉花，和福建高山乌龙茶窨制而成</div>
              </div>
              <div class="dec_item">
                <div class="dec_icon">
                  <img
                    src="../../assets/productDetail/icon.png"
                    alt=""
                    srcset=""
                  />
                </div>
                <div class="dec_item_content">100%原叶茶萃取</div>
              </div>
              <div class="dec_item">
                <div class="dec_icon">
                  <img
                    src="../../assets/productDetail/icon.png"
                    alt=""
                    srcset=""
                  />
                </div>
                <div class="dec_item_content">
                  0糖0脂0能量，不添加香精不添加防腐剂
                </div>
              </div>
            </div>
            <div class="dec_item_mid">
              <div class="dec_title">产品规格</div>
              <div class="dec_content">净含量：500mL/900mL </div>
            </div>
          </div>
        </div>
      </div>
    </div>
    <!-- 13 高山花茶系列 竹香乌龙茶 -->
    <div class="rangcha" v-if="product_id == 13">
      <div class="detail_content">
        <div class="p_img">
          <img
            src="https://rangcha-oss.oss-cn-beijing.aliyuncs.com/v3_website/product/3-2-2.png"
            alt=""
            srcset=""
          />
        </div>
        <div class="p_dec_box">
          <div class="series_title">
            <span>让茶.高山茶系列</span>
            <img src="../../assets/productDetail/b_a.png" alt="" srcset="" />
          </div>
          <div class="title">竹香乌龙茶</div>
          <div class="dec_box">
            <div class="dec_item_left">
              <div class="dec_title">产品介绍</div>
              <div class="dec_content">
                <div class="dec_content_item">竹香清新，乌龙醇爽</div>
                <div class="dec_content_item">精选海拔800米以上高山乌龙茶</div>
                <div class="dec_content_item">淡竹叶粉添加</div>
              </div>
            </div>
            <div class="dec_item_right">
              <div class="dec_title">产品特性</div>
              <div class="dec_item">
                <div class="dec_icon">
                  <img
                    src="../../assets/productDetail/icon.png"
                    alt=""
                    srcset=""
                  />
                </div>
                <div class="dec_item_content">江南淡竹叶搭配福建高山乌龙茶</div>
              </div>
              <div class="dec_item">
                <div class="dec_icon">
                  <img
                    src="../../assets/productDetail/icon.png"
                    alt=""
                    srcset=""
                  />
                </div>
                <div class="dec_item_content">100%原叶茶萃取</div>
              </div>
              <div class="dec_item">
                <div class="dec_icon">
                  <img
                    src="../../assets/productDetail/icon.png"
                    alt=""
                    srcset=""
                  />
                </div>
                <div class="dec_item_content">
                  0糖0脂0能量
                </div>
              </div>
            </div>
            <div class="dec_item_mid">
              <div class="dec_title">产品规格</div>
              <div class="dec_content">净含量：500mL</div>
            </div>
          </div>
        </div>
      </div>
    </div>
    <!-- 14 高山花茶系列 桂花乌龙茶 -->
    <div class="rangcha" v-if="product_id == 14">
      <div class="detail_content">
        <div class="p_img">
          <img
          src="https://rangcha-oss.oss-cn-beijing.aliyuncs.com/v3_website/product/3-2-3.png"
            alt=""
            srcset=""
          />
        </div>
        <div class="p_dec_box">
          <div class="series_title">
            <span>让茶.高山茶系列</span>
            <img src="../../assets/productDetail/b_a.png" alt="" srcset="" />
          </div>
          <div class="title">桂花乌龙茶</div>
          <div class="dec_box">
            <div class="dec_item_left">
              <div class="dec_title">产品介绍</div>
              <div class="dec_content">
                <div class="introduce_item">桂花清芬 乌龙醇爽</div>
                <div class="introduce_item">海拔800米以上高山乌龙茶</div>
              </div>
            </div>
            <div class="dec_item_right">
              <div class="dec_title">产品特性</div>
              <div class="dec_item">
                <div class="dec_icon">
                  <img
                    src="../../assets/productDetail/icon.png"
                    alt=""
                    srcset=""
                  />
                </div>
                <div class="dec_item_content">广西金桂邂逅福建乌龙茶</div>
              </div>
              <div class="dec_item">
                <div class="dec_icon">
                  <img
                    src="../../assets/productDetail/icon.png"
                    alt=""
                    srcset=""
                  />
                </div>
                <div class="dec_item_content">100%原叶茶萃取</div>
              </div>
              <div class="dec_item">
                <div class="dec_icon">
                  <img
                    src="../../assets/productDetail/icon.png"
                    alt=""
                    srcset=""
                  />
                </div>
                <div class="dec_item_content">
                  0糖0脂0能量，不添加香精不添加防腐剂
                </div>
              </div>
            </div>
            <div class="dec_item_mid">
              <div class="dec_title">产品规格</div>
              <div class="dec_content">净含量：500mL</div>
            </div>
          </div>
        </div>
      </div>
    </div>
<!-- 21 高山花茶系列 饭后乌龙-->
<div class="rangcha" v-if="product_id == 21">
      <div class="detail_content">
        <div class="p_img">
          <img
          src="https://rangcha-oss.oss-cn-beijing.aliyuncs.com/v3_website/product/3-3-2.png"
            alt=""
            srcset=""
          />
        </div>
        <div class="p_dec_box">
          <div class="series_title">
            <span>让茶.饭后茶系列</span>
            <img src="../../assets/productDetail/b_a.png" alt="" srcset="" />
          </div>
          <div class="title">饭后乌龙</div>
          <div class="dec_box">
            <div class="dec_item_left">
              <div class="dec_title">产品介绍</div>
              <div class="dec_content">
                <div class="introduce_item">以中式养生为灵感</div>
                <div class="introduce_item">山楂的清新果酸融入桂圆的甜美香气</div>
                <div class="introduce_item">饭后一瓶，自然清爽</div>
              </div>
            </div>
            <div class="dec_item_right">
              <div class="dec_title">产品特性</div>
              <div class="dec_item">
                <div class="dec_icon">
                  <img
                    src="../../assets/productDetail/icon.png"
                    alt=""
                    srcset=""
                  />
                </div>
                <div class="dec_item_content">海拔800米以上高山茶园</div>
              </div>
              <div class="dec_item">
                <div class="dec_icon">
                  <img
                    src="../../assets/productDetail/icon.png"
                    alt=""
                    srcset=""
                  />
                </div>
                <div class="dec_item_content">0糖0能量</div>
              </div>
              <div class="dec_item">
                <div class="dec_icon">
                  <img
                    src="../../assets/productDetail/icon.png"
                    alt=""
                    srcset=""
                  />
                </div>
                <div class="dec_item_content">
                  原叶茶萃取
                </div>
              </div>
              <div class="dec_item">
                <div class="dec_icon">
                  <img
                    src="../../assets/productDetail/icon.png"
                    alt=""
                    srcset=""
                  />
                </div>
                <div class="dec_item_content">
                  添加山楂浓缩液
                </div>
              </div>
            </div>
            <div class="dec_item_mid">
              <div class="dec_title">产品规格</div>
              <div class="dec_content">净含量：500mL</div>
            </div>
          </div>
        </div>
      </div>
    </div>
    <div class="rangcha" v-if="product_id == 20">
      <div class="detail_content">
        <div class="p_img">
          <img
          src="https://rangcha-oss.oss-cn-beijing.aliyuncs.com/v3_website/product/3-3-1.png"
            alt=""
            srcset=""
          />
        </div>
        <div class="p_dec_box">
          <div class="series_title">
            <span>让茶.饭后茶系列</span>
            <img src="../../assets/productDetail/b_a.png" alt="" srcset="" />
          </div>
          <div class="title">饭后普洱</div>
          <div class="dec_box">
            <div class="dec_item_left">
              <div class="dec_title">产品介绍</div>
              <div class="dec_content">
                <div class="introduce_item">以中式养生为灵感</div>
                <div class="introduce_item">普洱熟茶，搭配清新菊花</div>
                <div class="introduce_item">饭后一瓶，自然清爽</div>
              </div>
            </div>
            <div class="dec_item_right">
              <div class="dec_title">产品特性</div>
              <div class="dec_item">
                <div class="dec_icon">
                  <img
                    src="../../assets/productDetail/icon.png"
                    alt=""
                    srcset=""
                  />
                </div>
                <div class="dec_item_content">海拔800米以上高山茶园</div>
              </div>
              <div class="dec_item">
                <div class="dec_icon">
                  <img
                    src="../../assets/productDetail/icon.png"
                    alt=""
                    srcset=""
                  />
                </div>
                <div class="dec_item_content">0糖0能量</div>
              </div>
              <div class="dec_item">
                <div class="dec_icon">
                  <img
                    src="../../assets/productDetail/icon.png"
                    alt=""
                    srcset=""
                  />
                </div>
                <div class="dec_item_content">
                  原叶茶萃取
                </div>
              </div>
            </div>
            <div class="dec_item_mid">
              <div class="dec_title">产品规格</div>
              <div class="dec_content">净含量：500mL</div>
            </div>
          </div>
        </div>
      </div>
    </div>
    <!-- 22 高山花茶系列 红豆薏米水-->
    <div class="rangcha" v-if="product_id == 22">
      <div class="detail_content">
        <div class="p_img">
          <img
          src="https://rangcha-oss.oss-cn-beijing.aliyuncs.com/v3_website/product/3-4-1.png"
            alt=""
            srcset=""
          />
        </div>
        <div class="p_dec_box">
          <div class="series_title">
            <span>让茶.轻养水系列</span>
            <img src="../../assets/productDetail/b_a.png" alt="" srcset="" />
          </div>
          <div class="title">红豆薏米水</div>
          <div class="dec_box">
            <div class="dec_item_left">
              <div class="dec_title">产品介绍</div>
              <div class="dec_content">
                <div class="introduce_item">豆谷清香，清甜不腻</div>
                <div class="introduce_item">精选地道原料，精心配比</div>
                <div class="introduce_item">植物轻养水，就喝植与养</div>
              </div>
            </div>
            <div class="dec_item_right">
              <div class="dec_title">产品特性</div>
              <div class="dec_item">
                <div class="dec_icon">
                  <img
                    src="../../assets/productDetail/icon.png"
                    alt=""
                    srcset=""
                  />
                </div>
                <div class="dec_item_content">0糖0脂0能量</div>
              </div>
              <div class="dec_item">
                <div class="dec_icon">
                  <img
                    src="../../assets/productDetail/icon.png"
                    alt=""
                    srcset=""
                  />
                </div>
                <div class="dec_item_content">配料干净</div>
              </div>
              <div class="dec_item">
                <div class="dec_icon">
                  <img
                    src="../../assets/productDetail/icon.png"
                    alt=""
                    srcset=""
                  />
                </div>
                <div class="dec_item_content">
                  传统熬煮工艺，冷热都好喝
                </div>
              </div>
            </div>
            <div class="dec_item_mid">
              <div class="dec_title">产品规格</div>
              <div class="dec_content">净含量：500mL</div>
            </div>
          </div>
        </div>
      </div>
    </div>
    
    <!-- 23 高山花茶系列 红枣枸杞水-->
    <div class="rangcha" v-if="product_id == 23">
      <div class="detail_content">
        <div class="p_img">
          <img
          src="https://rangcha-oss.oss-cn-beijing.aliyuncs.com/v3_website/product/3-4-2.png"
            alt=""
            srcset=""
          />
        </div>
        <div class="p_dec_box">
          <div class="series_title">
            <span>让茶.轻养水系列</span>
            <img src="../../assets/productDetail/b_a.png" alt="" srcset="" />
          </div>
          <div class="title">红枣枸杞水</div>
          <div class="dec_box">
            <div class="dec_item_left">
              <div class="dec_title">产品介绍</div>
              <div class="dec_content">
                <div class="introduce_item">红枣的甘美搭配枸杞的清甜，口感清爽不腻</div>
                <div class="introduce_item">双红配方，双重滋养</div>
                <div class="introduce_item">植物轻养水，就喝植与养</div>
              </div>
            </div>
            <div class="dec_item_right">
              <div class="dec_title">产品特性</div>
              <div class="dec_item">
                <div class="dec_icon">
                  <img
                    src="../../assets/productDetail/icon.png"
                    alt=""
                    srcset=""
                  />
                </div>
                <div class="dec_item_content">0糖0脂0能量</div>
              </div>
              <div class="dec_item">
                <div class="dec_icon">
                  <img
                    src="../../assets/productDetail/icon.png"
                    alt=""
                    srcset=""
                  />
                </div>
                <div class="dec_item_content">配料干净</div>
              </div>
              <div class="dec_item">
                <div class="dec_icon">
                  <img
                    src="../../assets/productDetail/icon.png"
                    alt=""
                    srcset=""
                  />
                </div>
                <div class="dec_item_content">
                  传统熬煮工艺，冷热都好喝
                </div>
              </div>
            </div>
            <div class="dec_item_mid">
              <div class="dec_title">产品规格</div>
              <div class="dec_content">净含量：500mL</div>
            </div>
          </div>
        </div>
      </div>
    </div>
    <PCConncatUs />
  </div>
</template>

<script>
import PCConncatUs from "@/components/PCConncatUs.vue";
export default {
  name: "RangchaProductDetail",
  components: {
    PCConncatUs,
  },
  props: {},
  setup() {},
  data() {
    return {
      product_id: "1", //产品ID
    };
  },
  methods: {},
  created() {
    this.product_id = this.$route.query.pid;
  },
  mounted() {},
};
</script>

<style scoped lang="less">
#RangchaProductDetail {
  /*  width: 1006px; */
  width: 100%;
  .banner {
    width: 100%;
    img {
      width: 100%;
    }
  }
  .rangcha {
    .detail_content {
      width: 858px;
      margin: 40px auto 0 auto;
      overflow: hidden;
      .p_img {
        width: 121px;
        /* height: 428px; */
        float: left;
        img {
          width: 100%;
          /* height: 100%; */
        }
      }
      .p_dec_box {
        /* width: 520x; */
        float: left;
        margin-left: 60px;
        .series_title {
          width: 363px;
          font-size: 36px;
          font-weight: bold;
          color: #45B035;
          margin-top: 64px;
          position: relative;
          text-align: center;
          img {
            width: 100%;
            height: 17px;
            position: absolute;
            left: 0;
            bottom: -4px;
            opacity: 0.8;
          }
        }
        .title {
          font-size: 26px;
          font-weight: 400;
          color: #121212;
          margin-top: 22px;
        }
        .dec_box {
          width: 100%;
          margin-top: 48px;
          overflow: hidden;
          .dec_item_left {
            width: 180px;
            float: left;
            .dec_title {
              width: 46px;
              height: 132px;
              font-size: 22px;
              font-weight: 500;
              color: #ffffff;
              float: left;
              writing-mode: vertical-rl; //垂直方向，从左向右
              line-height: 46px;
              letter-spacing: 5px;
              text-align: center;
              background: #45B035;
            }
            .dec_content {
              /* width: 64px;
              height: 200px; */
              float: left;
              margin-left: 14px;
              writing-mode: vertical-lr; //垂直方向，从左向右
              line-height: 24px;
              letter-spacing: 5px;
              .dec_content_item {
                height: 100%;
                writing-mode: vertical-lr; //垂直方向，从左向右
                /* line-height: 32px; */
                /* text-align: center; */
                letter-spacing: 4px;
                float: left;
                margin-right: 6px;
              }
            }
          }
          .dec_item_right {
            float: left;
            margin-left: 3px;
            .dec_title {
              width: 46px;
              height: 132px;
              font-size: 22px;
              font-weight: 500;
              color: #ffffff;
              float: left;
              writing-mode: vertical-rl; //垂直方向，从左向右
              line-height: 46px;
              letter-spacing: 5px;
              text-align: center;
              background: #45B035;
            }
            .dec_item {
              width: 30px;
              float: left;
              margin-left: 14px;

              .dec_icon {
                width: 24px;
                height: 24px;
                margin-left: 5px;
                img {
                  width: 24px;
                  height: 24px;
                }
              }
              .dec_item_content {
                width: 100%;
                font-size: 16px;
                margin-top: 6px;
                font-weight: 400;
                color: #121212;
                text-align: center;
                writing-mode: vertical-lr; //垂直方向，从左向右
                letter-spacing: 6px;
                line-height: 30px;
              }
            }
          }
          .dec_item_mid {
            min-width: 84px;
            float: left;
            margin-left: 25px;
            .dec_title {
              width: 46px;
              height: 132px;
              font-size: 22px;
              font-weight: 500;
              color: #ffffff;
              float: left;
              writing-mode: vertical-rl; //垂直方向，从左向右
              line-height: 46px;
              letter-spacing: 5px;
              text-align: center;
              background: #45B035;
            }
            .dec_content {
              /* width: 64px; */
              height: 282px;
              float: left;
              margin-left: 14px;
              writing-mode: vertical-lr; //垂直方向，从左向右
              line-height: 24px;
              letter-spacing: 5px;
              /* text-align: center; */
            }
          }
        }
      }
    }
  }
}
</style>
